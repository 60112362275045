import React, { useEffect, useState } from 'react';

import WrapperPage from '../WrapperPage';
import FormGroup from '../../slices/FormGroup';
import useCRUDQuery from '../../hooks/api/useCRUDQuery';
import {
	NAS,
	NASConnectionResponse,
	NASFirewallFilterResponse,
	NASLastSyncResponse,
	NASHealthCheckerResponse,
	NASPPPoEServerResponse,
	NASRadiusResponse,
} from '../../shared/models/infra/NAS';
import useAPINASRouterOS from '../../hooks/api/useAPINASRouterOS';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Overlay from '../../components/Overlay';
import { timeSince } from '../../shared/utils';
import DataTable from '../../components/DataTable';
import MessageBox from '../../slices/MessageBox';
import { Check, Clock, CloudOff, Info, TriangleAlert, LoaderCircle, SquarePen, Link2Off } from 'lucide-react';
import { APIError } from '@/shared/api_errors';

function NASConfigurationPage() {
	const { response: nas } = useCRUDQuery<NAS>('/infra/nas', { initialPageSize: 100 });
	const [error, setError] = useState<APIError | undefined>();

	function handleOnError(error: APIError) {
		setError(error);
	}

	function clearError() {
		setError(undefined);
	}

	return (
		<WrapperPage title="NAS Control Panel" error={error} onAlertClose={clearError}>
			<div className="m-4 flex flex-wrap gap-4">
				{nas?.rows.map((nas) => <NASConfiguration key={nas.id} nas={nas} onError={handleOnError} />)}
			</div>
		</WrapperPage>
	);
}

function NASHeaderAction({ nas, connection }: { nas: NAS; connection?: NASConnectionResponse }) {
	return (
		<div className="flex flex-col items-end">
			<span className="text-sm text-light">{nas.api_url}</span>
			<span className="text-sm font-bold text-light">{connection?.connection.version}</span>
		</div>
	);
}

function NASConfiguration({ nas, onError }: { nas: NAS; onError: (error: APIError) => void }) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [connectionChecked, setConnectionChecked] = useState(false);

	const {
		connectionStatus,
		loadingConnectionStatus,
		configuration,
		sync,
		loadingConfiguration,
		loadingSync,
		error,
		applyConfiguration,
		runSync,
		actionLoading,
	} = useAPINASRouterOS({
		nasId: nas.id,
		mustFetchConnectionStatus: !connectionChecked,
		mustFetchConfiguration: nas.pppoe_server && connectionChecked,
		mustFetchSync: true,
	});

	useEffect(() => {
		if (connectionStatus && connectionStatus.connection.state) {
			setConnectionChecked(true);
			return;
		}
	}, [nas, connectionStatus]);

	useEffect(() => {
		if (error) onError(error);
	}, [error]);

	async function handleApplyConfiguration() {
		await applyConfiguration();
		setOpen(false);
	}

	return (
		<div className="w-full md:w-96 flex">
			<FormGroup
				title={nas.name}
				titleAction={{ icon: SquarePen, action: () => navigate(`/infra/nas/${nas.id}`) }}
				action={<NASHeaderAction nas={nas} connection={connectionStatus} />}
				grow
			>
				<div className="h-full flex flex-col gap-6 justify-between">
					<ul className="flex flex-col gap-2">
						<li className="pb-2 border-b border-gray-200 last:border-none">
							<NASConnection connection={connectionStatus} loading={loadingConnectionStatus} />
						</li>
						{nas.pppoe_server && (
							<>
								<li className="pb-2 border-b border-gray-200 last:border-none">
									<NASRadius
										connection={connectionStatus}
										radius={configuration?.radius}
										loading={loadingConfiguration}
									/>
								</li>
								<li className="pb-2 border-b border-gray-200 last:border-none">
									<NASPPPoEServers
										connection={connectionStatus}
										pppoe_server={configuration?.pppoe_server}
										loading={loadingConfiguration}
									/>
								</li>
								<li className="pb-2 border-b border-gray-200 last:border-none">
									<NASFirewallFilter
										connection={connectionStatus}
										firewall_filter={configuration?.firewall_filter}
										loading={loadingConfiguration}
									/>
								</li>
								<li className="pb-2 border-b border-gray-200 last:border-none">
									<NASHealthChecker
										connection={connectionStatus}
										healthChecker={configuration?.health_checker}
										loading={loadingConfiguration}
									/>
								</li>
								<li className="pb-2">
									<NASLastSync
										lastSync={sync}
										loading={loadingSync}
										runSync={runSync}
										nasId={nas.id}
										actionLoading={actionLoading}
									/>
								</li>
							</>
						)}
					</ul>
					{!loadingConnectionStatus &&
						!loadingConfiguration &&
						connectionStatus?.connection.state &&
						!configuration?.valid &&
						nas.pppoe_server && (
							<Button
								text="Fix Configurations"
								style="roundedOutline"
								variant="warning"
								icon={TriangleAlert}
								onClick={() => setOpen(true)}
							/>
						)}
				</div>
			</FormGroup>
			{open && (
				<Overlay>
					<MessageBox title={nas.name}>
						<span>The following configurations will be fixed.</span>
						<textarea className="w-full font-mono text-xs border-[1px] p-2 resize-none" disabled>
							{[
								configuration?.radius.status !== 'ok' && '- RADIUS',
								configuration?.firewall_filter.status !== 'ok' && '- NAS Firewall',
								configuration?.firewall_filter.status !== 'ok' &&
									'  • Firewall Filter (foward, drop, gravity-list)',
								configuration?.firewall_filter.status !== 'ok' && '  • Interface List',
								configuration?.health_checker.status !== 'ok' && '- Health Checker',
								configuration?.health_checker.status !== 'ok' && '  • RADIUS checker script',
								configuration?.health_checker.status !== 'ok' && '  • RADIUS checker scheduler',
							]
								.filter((item) => item)
								.join('\n')}
						</textarea>
						<div className="mt-4 flex gap-2">
							<div className="w-32">
								<Button
									block
									text="Fix Now"
									icon={actionLoading ? LoaderCircle : TriangleAlert}
									iconSpin={actionLoading}
									variant="warning"
									onClick={handleApplyConfiguration}
									disabled={actionLoading}
								/>
							</div>
							<div className="w-32">
								<Button
									block
									text="Close"
									style="outline"
									variant="light"
									onClick={() => setOpen(false)}
								/>
							</div>
						</div>
					</MessageBox>
				</Overlay>
			)}
		</div>
	);
}

function NASConnection({ connection, loading }: { connection?: NASConnectionResponse; loading: boolean }) {
	const isLoading = loading && !connection;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <LoaderCircle className="text-light-extra animate-spin" />}
				{!isLoading && connection?.connection.state && <Check className="text-success" />}
				{!isLoading && !connection?.connection.state && <Link2Off className="text-warning" />}
			</div>
			<div>
				<span className="font-bold">Connection:</span>
				<div className="text-light">
					{isLoading && <span>Connecting...</span>}
					{!isLoading && connection?.connection.state && <span>Connected</span>}
					{!isLoading && !connection?.connection.state && <span>{connection?.connection.msg}</span>}
				</div>
			</div>
		</div>
	);
}

function NASRadius({
	connection,
	radius,
	loading,
}: {
	connection?: NASConnectionResponse;
	radius?: NASRadiusResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !radius;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <LoaderCircle className="text-light-extra animate-spin" />}
				{!isLoading && radius?.status === 'ok' && <Check className="text-success" />}
				{!isLoading && connected && radius?.status !== 'ok' && <TriangleAlert className="text-warning" />}
				{!waitingConnection && !connected && <CloudOff className="text-light-extra" />}
				{waitingConnection && <Clock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">RADIUS:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && radius?.status === 'ok' && <span>{radius.message}</span>}
					{connected && !isLoading && radius && radius?.status !== 'ok' && (
						<ul className="list-disc ml-4">
							{radius.issues?.map((issue) => <li key={issue}>{issue}</li>)}
						</ul>
					)}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASPPPoEServers({
	connection,
	pppoe_server,
	loading,
}: {
	connection?: NASConnectionResponse;
	pppoe_server?: NASPPPoEServerResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !pppoe_server;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <LoaderCircle className="text-light-extra animate-spin" />}
				{!isLoading && pppoe_server?.status === 'ok' && <Check className="text-success" />}
				{!isLoading && connected && pppoe_server?.status !== 'ok' && <TriangleAlert className="text-warning" />}
				{!waitingConnection && !connected && <CloudOff className="text-light-extra" />}
				{waitingConnection && <Clock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">PPPoE Servers:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && pppoe_server?.status === 'ok' && (
						<ul className="flex flex-col">
							{pppoe_server.servers
								?.filter((server) => !server.disabled)
								.map((server) => (
									<span
										key={server.service_name}
									>{`${server.service_name}:${server.interface}:profile(${server.default_profile})`}</span>
								))}
						</ul>
					)}
					{connected && !isLoading && pppoe_server && pppoe_server?.status !== 'ok' && (
						<span>{pppoe_server.message}</span>
					)}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASFirewallFilter({
	connection,
	firewall_filter,
	loading,
}: {
	connection?: NASConnectionResponse;
	firewall_filter?: NASFirewallFilterResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !firewall_filter;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <LoaderCircle className="text-light-extra animate-spin" />}
				{!isLoading && firewall_filter?.status === 'ok' && <Check className="text-success" />}
				{!isLoading && connected && firewall_filter?.status !== 'ok' && (
					<TriangleAlert className="text-warning" />
				)}
				{!waitingConnection && !connected && <CloudOff className="text-light-extra" />}
				{waitingConnection && <Clock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">NAS Firewall:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{connected && !isLoading && firewall_filter && <span>{firewall_filter.message}</span>}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASHealthChecker({
	connection,
	healthChecker,
	loading,
}: {
	connection?: NASConnectionResponse;
	healthChecker?: NASHealthCheckerResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !healthChecker;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <LoaderCircle className="text-light-extra animate-spin" />}
				{!isLoading && healthChecker?.status === 'ok' && <Check className="text-success" />}
				{!isLoading && connected && healthChecker?.status !== 'ok' && (
					<TriangleAlert className="text-warning" />
				)}
				{!waitingConnection && !connected && <CloudOff className="text-light-extra" />}
				{waitingConnection && <Clock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">Health Checker:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && healthChecker?.status === 'ok' && <span>{healthChecker.message}</span>}
					{connected && !isLoading && healthChecker && healthChecker?.status !== 'ok' && (
						<ul className="list-disc ml-4">
							{healthChecker.issues?.map((issue) => <li key={issue}>{issue}</li>)}
						</ul>
					)}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASLastSync({
	lastSync,
	loading,
	nasId,
	runSync,
	actionLoading,
}: {
	lastSync?: NASLastSyncResponse;
	loading: boolean;
	nasId: string;
	runSync?: (nas_id: string) => Promise<boolean>;
	actionLoading: boolean;
}) {
	const [open, setOpen] = useState(false);
	const isLoading = loading && !lastSync;

	let status: 'success' | 'failed' | 'loading' | 'never' = 'loading';
	if (!isLoading) {
		if (lastSync && lastSync.status !== 'never') status = lastSync.status === 'ok' ? 'success' : 'failed';
		else status = 'never';
	} else if (isLoading) {
		status = 'loading';
	} else {
		status = 'never';
	}

	const colors = {
		success: 'text-success',
		failed: 'text-warning',
		loading: 'text-light-extra',
		never: 'text-info',
		waiting: 'text-light-extra',
	};
	const color = colors[status];

	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				<Info className={`${color}`} />
			</div>
			<div>
				<span className="font-bold">Last Synchronization:</span>
				<div className="text-light">
					{['success', 'failed', 'never'].indexOf(status) > -1 && (
						<div className="flex">
							<span className={`${color} font-bold`}>
								{status === 'success' && <>Succeeded&nbsp;</>}
								{status === 'failed' && <>Failed&nbsp;</>}
							</span>
							<span>{(lastSync?.finished_at && timeSince(lastSync?.finished_at)) || 'Never'}</span>
							{status === 'failed' && (
								<>
									&nbsp;
									<Button text="(details)" variant="link" onClick={() => setOpen(true)} />
								</>
							)}
							{runSync && (
								<>
									&nbsp;
									<Button
										text="(sync now)"
										variant="link"
										onClick={() => runSync(nasId)}
										disabled={actionLoading}
									/>
								</>
							)}
						</div>
					)}
					{status === 'loading' && <span>Loading last sync information...</span>}
				</div>
			</div>
			{open && lastSync && (
				<Overlay>
					<MessageBox title="Sync Failures">
						<div className="min-w-96 flex flex-col gap-2">
							{lastSync.subscription_failures && lastSync.subscription_failures.length > 0 && (
								<>
									<h3 className="font-bold text-sm">Subscriptions failures</h3>
									<div className="max-h-[30vh] max-w-[90vw] overflow-auto custom-scrollbar">
										<DataTable
											style="condensed"
											fields={[
												{
													title: 'Subscription ID',
													property: 'subscription_id',
													extractor: (row) => (
														<Button
															text={row.subscription_id}
															variant="link"
															onClick={() =>
																window.open(
																	`/subscriptions/${row.subscription_id}`,
																	'_blank',
																)
															}
														/>
													),
												},
												{
													title: 'Error Message',
													property: 'failure_message',
												},
											]}
											rows={lastSync.subscription_failures}
										/>
									</div>
								</>
							)}
							{lastSync.failure_message && (
								<>
									<h3 className="font-bold text-sm">General failure</h3>
									<textarea
										className="resize-none w-full p-2 max-h-24 bg-red-100 rounded-lg text-red-500"
										disabled
									>
										{lastSync.failure_message}
									</textarea>
								</>
							)}
						</div>
						<div className="flex gap-2">
							<Button text="Close" style="roundedOutline" size="xs" onClick={() => setOpen(false)} />
						</div>
					</MessageBox>
				</Overlay>
			)}
		</div>
	);
}

export default NASConfigurationPage;
